import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Metric, Text, BarChart, Grid, Card, Col } from '@tremor/react';
import { capitalizeFirstLetter } from '../../utils/stringUtils';

function PracticeStats() {
	const { practiceId } = useParams();
	const [practice, setPractice] = useState(null);
	const [practiceViews, setPracticeViews] = useState(0);
	const [practiceCompletions, setPracticeCompletions] = useState(0);
	const [countsByMonth, setCountsByMonth] = useState([]);
	const [sentimentCounts, setSentimentCounts] = useState([]);
	const [responses, setResponses] = useState([]);
	const [visits, setVisits] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			if (practiceId) {
				const prac = await fetchPractice(practiceId);
				await fetchPracticeViews(practiceId);
				await fetchTotalCompletions(prac);
				await fetchEngagementOverTime(practiceId);
				await fetchSentiments(practiceId);
				await fetchResponses(practiceId);
				await fetchVisits(practiceId);
			}
		};
		fetchData();
	}, [practiceId]);

	const fetchPractice = async (practiceId) => {
		const practiceResponse = await tetherApi.getPractice(practiceId);
		setPractice(practiceResponse.data);
		return practiceResponse.data;
	};

	const fetchPracticeViews = async (practiceId) => {
		const viewsResponse = await tetherApi.getPracticeViewsCount(practiceId);
		setPracticeViews(viewsResponse);
	};

	const fetchVisits = async (practiceId) => {
		const viewsResponse = await tetherApi.getAhoyMetricsCount({
			org_id: orgsStore.currentOrg.id,
			practice_id: practiceId,
		});
		setVisits(viewsResponse ?? 0);
	};

	const fetchTotalCompletions = async (prac) => {
		const completionsResponse = await tetherApi.getTotalPracticeCompletions(
			prac.id,
			moment(prac.createdAt).format('YYYY-MM-DD'),
		);
		setPracticeCompletions(completionsResponse ?? 0);
	};

	const fetchEngagementOverTime = async (practiceId) => {
		const completedCounts = [];
		const currentMonthCompletedCount = await tetherApi.getTimeBoundedPracticeCompletions(
			practiceId,
			moment().startOf('month').format('YYYY-MM-DD'),
		);
		const currentMonthStartedCount = await tetherApi.getTimeBoundedPracticeStarts(
			practiceId,
			moment().startOf('month').format('YYYY-MM-DD'),
		);
		completedCounts.push({
			month: moment().format('MMM'),
			Completions: currentMonthCompletedCount ?? 0,
			Started: currentMonthStartedCount ?? 0,
		});
		const offsets = [1, 2, 3, 4, 5];
		for await (const offset of offsets) {
			const momentForMonth = moment().subtract(offset, 'month');
			const startDate = momentForMonth.startOf('month').format('YYYY-MM-DD');
			const endDate = momentForMonth.endOf('month').format('YYYY-MM-DD');
			const count = await tetherApi.getTimeBoundedPracticeCompletions(practiceId, startDate, endDate);
			const startedCount = await tetherApi.getTimeBoundedPracticeStarts(practiceId, startDate, endDate);
			completedCounts.unshift({
				month: momentForMonth.format('MMM'),
				Completions: count ?? 0,
				Started: startedCount ?? 0,
			});
		}
		setCountsByMonth(completedCounts);
	};

	const fetchSentiments = async (practiceId) => {
		const sentiments = await tetherApi.getPracticeSentiments(practiceId);
		setSentimentCounts(sentiments);
	};

	const fetchResponses = async (practiceId) => {
		const anonResponses = await tetherApi.getPracticeAnonymousResponses(practiceId);
		setResponses(anonResponses.data);
	};

	if (!practice) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<div className="flex flex-row pt-8">
				{practice?.imageUrl ? (
					<img
						src={practice.secondaryImageUrl ?? practice.imageUrl}
						className="w-24 h-24 rounded-lg object-cover"
					/>
				) : (
					<div className="w-24 h-24 rounded-lg bg-gray-300"></div>
				)}
				<div className="pl-8 pt-4">
					<p className="text-2xl pb-.5 font-semibold text-gray-700 ">{practice.title}</p>
					<p className="text-base font-regular text-gray-500 ">{`Published ${moment(
						practice.createdAt,
					).format('MMMM Do, YYYY')} at ${moment(practice.createdAt).format('h:mma')}`}</p>
				</div>
			</div>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Visits</Text>
								<Metric className="font-medium">{visits}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Starts</Text>
								<Metric className="font-medium">{practiceViews}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Completions</Text>
								<Metric className="font-medium">{practiceCompletions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col className="h-full" numColSpanLg={4}>
					<Card className="h-full">
						<Text className="font-regular">Practice Engagement</Text>
						<BarChart
							className="mt-6"
							data={countsByMonth}
							index="month"
							categories={['Started', 'Completions']}
							yAxisWidth={48}
							showLegend={false}
							colors={[
								'violet',
								'blue',
								'blue',
								'sky',
								'cyan',
								'slate',
								'green',
								'pink',
								'orange',
								'red',
							]}
						/>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div>
							<Text className="font-regular">Practice Ratings</Text>
							<div className="flex flex-row pt-4">
								{Object.keys(sentimentCounts).map((key) => (
									<div className="flex flex-col items-center px-1" key={key}>
										<Text className="font-regular">{capitalizeFirstLetter(key)}</Text>
										<Text className="font-semibold">{`${
											sentimentCounts[key].percentage
												? sentimentCounts[key].percentage.toFixed(1)
												: 0
										}%`}</Text>
									</div>
								))}
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col numColSpanLg={6}>
					<Card>
						<Text className="font-regular mb-4">Anonymous Feedback</Text>
						{responses.map((resp) => (
							<Text className="mb-2 font-regular text-black" key={resp.id}>{`"${resp.mood.body}"`}</Text>
						))}
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(PracticeStats);
