import React, { useEffect } from 'react';
import {
	processJwt,
	sendCode,
	completeLogin,
	auth0LoginPage,
	sendCodeToEmail,
	completeLoginWithEmail,
} from '../utils/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import orgsStore from '../stores/OrgsStore';
import { Alert } from './Alert';
import userStore from '../stores/UserStore';

const LoginForm = () => {
	const [phone, setPhone] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [jwt, setJwt] = React.useState(null);
	const [type, setType] = React.useState('phone');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (window.location.hash !== '') {
			handleCallback();
		} else {
			handleRender();
		}
	}, [jwt]);

	const handleCallback = async () => {
		orgsStore.removeCurrentOrg();

		processJwt(auth0LoginPage, async (jwtResult) => {
			userStore.setJwt(jwtResult);

			setJwt(jwtResult);

			window.location.hash = '';
			window.history.replaceState('', '', window.location.pathname);
		});
	};

	const handleRender = async () => {
		if (jwt && userStore.getJwt()) {
			const user = await userStore.fetchUser();

			if (user && (user.isSuperUser || user.orgs.length > 0)) {
				return navigate(location.state?.from?.pathname || '/dashboard');
			} else if (user && user.orgs.length === 0) {
				return navigate('/create-organization');
			}
		}
	};

	const handlePhoneClick = (event) => {
		event.preventDefault();

		const phone = document.getElementById('phone').value;

		setPhone(phone);

		sendCode(auth0LoginPage, phone);
	};

	const handleEmailClick = (event) => {
		event.preventDefault();

		const email = document.getElementById('email').value;
		setEmail(email);

		sendCodeToEmail(auth0LoginPage, email);
	};

	const handleCodeSubmit = (event) => {
		event.preventDefault();

		const verificationCode = document.getElementById('verificationCode').value;
		if (type === 'phone') {
			completeLogin(auth0LoginPage, phone, verificationCode);
		} else {
			completeLoginWithEmail(auth0LoginPage, email, verificationCode);
		}
	};

	return (
		<div className="min-h-full h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-neutral-25 ">
			<Alert />

			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<img
					className="mx-auto h-3.5 w-auto"
					src={
						window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
							? './tether-white-wordmark.svg'
							: './tether-wordmark-black.svg'
					}
					alt="Tether"
				/>
				<h2 className="mt-16 text-center xs:text-3xl text-4xl font-semibold tracking-tight text-black-900 ">
					Sign in to your account
				</h2>
			</div>

			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="py-6 px-4 sm:px-10">
					<form className={phone || email ? 'hidden' : 'space-y-6'} action="#" method="POST">
						<div>
							<label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
								{`${type === 'phone' ? 'Phone' : 'Email'}`}
							</label>
							<div className="mt-1">
								{type === 'phone' ? (
									<input
										id="phone"
										name="phone"
										type="tel"
										maxLength="10"
										autoComplete="phone"
										required
										placeholder="Phone Number"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
									/>
								) : (
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										required
										placeholder="Email"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
									/>
								)}
							</div>
						</div>

						<div>
							<button
								type="reset"
								className="disabled:opacity-25 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-800 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600 border border-gray-200"
								onClick={() => setType(type === 'phone' ? 'email' : 'phone')}
							>
								{`Use ${type === 'phone' ? 'Email' : 'Phone Number'}`}
							</button>
							<button
								type="submit"
								className="mt-4 disabled:opacity-25 flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
								onClick={(event) => {
									if (type === 'phone') {
										handlePhoneClick(event);
									} else {
										handleEmailClick(event);
									}
								}}
							>
								Sign in
							</button>
						</div>
					</form>

					<form className={phone || email ? 'space-y-6' : 'hidden'} action="#" method="POST">
						<div>
							<div className="mt-1">
								<input
									id="verificationCode"
									name="verificationCode"
									type="numeric"
									required
									placeholder="Code"
									className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="disabled:opacity-25 flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
								onClick={handleCodeSubmit}
							>
								Submit
							</button>

							<button
								className="mt-4 disabled:opacity-25 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-800 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600 border border-gray-200"
								onClick={(event) => {
									event.preventDefault();
									if (type === 'phone') {
										handlePhoneClick(event);
									} else {
										handleEmailClick(event);
									}
								}}
							>
								Send Code Again
							</button>

							<button
								type="reset"
								className="mt-4 disabled:opacity-25 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-neutral-800 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600 border border-gray-200"
								onClick={() => {
									setPhone(null);
									setEmail(null);
								}}
							>
								Back
							</button>
						</div>
					</form>
				</div>
			</div>
			{/* <div className="mt-2">
				<p className="mt-2 text-center text-sm text-gray-500  font-regular">
					Need help?{' '}
					<a
						href="mailto:support@withtether.com"
						className="text-zinc-500 hover:text-zinc-500 font-medium"
					>
						Contact support
					</a>
				</p>
			</div> */}
			<div className="mt-2 sm:mx-auto sm:max-w-sm justify-center">
				<p className="mt-2 text-center text-sm text-gray-800  font-regular">
					By signing in to your account, you agree to our{' '}
					<a
						href="https://www.withtether.com/legal/terms-and-conditions"
						className="text-zinc-900 hover:text-zinc-500 font-medium"
					>
						Terms and Conditions
					</a>
					&nbsp;and&nbsp;
					<a
						href="https://www.withtether.com/legal/privacy-policy"
						className="text-zinc-900 hover:text-zinc-500 font-medium"
					>
						Privacy Policy
					</a>
				</p>
			</div>
		</div>
	);
};

export default LoginForm;
