import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { humanize } from '../../utils/stringUtils';
import { statusPill } from '../../utils/pillHelper';
import Pagination from '../Pagination';
import moment from 'moment';
import { statusLabels } from '../../utils/humanReadableLabels';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

// import IntroductionCard from '../IntroductionCard';

function PracticesList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [practices, setPractices] = useState([]);
	const [sortBy, setSortBy] = useState('title');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchPractices();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchPractices();
	}, [sortBy, sortOrder]);

	const fetchPractices = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const practicesResponse = await tetherApi.getPractices(page, perPage, opts);

		setPractices(practicesResponse.data);

		const totalCount = practicesResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + practicesResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-0 lg:pb-0">Practices</h1>
					{/* <h3 className="font-regular text-gray-500">Guided exercises for step-by-step instruction</h3> */}
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://support.withtether.com/en/articles/9535057-about-practices"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/practices/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Practice
					</Link>
				</div>
			</div>
			{/* <IntroductionCard
				localStorageKey="hidePracticeVideo"
				vimeoLink="https://player.vimeo.com/video/858737739?h=b3c34f8db3"
				bullets={[
					'Common use cases include prayer guides of all kinds, creative meditation experiences, bible study methods, and other spiritual "how to\'s".',
					'Be as concise as possible! Try not to make them scroll on a text step, and keep your Practice to fewer than 8 steps!',
					'Specific is usually more intriguing than general! "Pray in the midst of grief" is more useful than "How to Pray".',
				]}
				type="Practices"
			/> */}
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{practices.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No practices to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No practices are available.
										</p>
										<Link
											to="/practices/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Practice
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'title') {
														setSortBy('title');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Name
													{sortBy === 'title' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'status') {
														setSortBy('status');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Status
													{sortBy === 'status' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Author
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'practice_type') {
														setSortBy('practice_type');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Category
													{sortBy === 'practice_type' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'is_featured') {
														setSortBy('is_featured');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Featured
													{sortBy === 'is_featured' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'is_members_only') {
														setSortBy('is_members_only');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Members Only
													{sortBy === 'is_members_only' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'updated_at') {
														setSortBy('updated_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last Updated
													{sortBy === 'updated_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Stats
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{practices.map((practice, practiceIdx) => (
											<tr
												key={practice.id}
												className={practiceIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 max-w-xs truncate">
													{practice.title}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 font-regular">
													<span
														className={
															'inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium ' +
															statusPill(practice.status)
														}
													>
														{statusLabels[practice.status] || 'No Status'}
													</span>
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 max-w-xs truncate font-regular">
													{practice?.author?.name}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 max-w-xs truncate font-regular">
													{humanize(practice.practiceType)}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 font-regular">
													{practice.isFeatured ? 'Yes' : 'No'}
												</td>
												<td className="px-3 py-4 text-sm text-gray-500 font-regular">
													{practice.isMembersOnly ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(practice.updatedAt).format('LL')}
												</td>

												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/practices/${practice.id}/stats`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Stats<span className="sr-only">, {practice.name}</span>
													</Link>
												</td>

												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/practices/${practice.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(PracticesList);
